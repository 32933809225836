import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1203d86e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))

const _183ad766 = () => interopDefault(import('../pages/opties.vue' /* webpackChunkName: "pages/opties" */))
const _564e8b89 = () => interopDefault(import('../pages/poorten.vue' /* webpackChunkName: "pages/poorten" */))
const _39d729a0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f741795c = () => interopDefault(import('../pages/configurator/_slug.vue' /* webpackChunkName: "pages/configurator/_slug" */))
const _38208858 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _1203d86e,
    pathToRegexpOptions: {"strict":true},
    name: "contact___nl"
  }, {
    path: "/hardhouten-poort",
    redirect: "/"
  }, {
    path: "/houten-draaipoort",
    redirect: "/"
  }, {
    path: "/houten-poort-landelijk",
    redirect: "/"
  }, {
    path: "/houten-poort-oprit",
    redirect: "/"
  }, {
    path: "/houten-schuifpoort",
    redirect: "/"
  }, {
    path: "/houten-toegangspoort",
    redirect: "/"
  }, {
    path: "/houten-tuinpoort",
    redirect: "/"
  }, {
    path: "/luxe-houten-poort",
    redirect: "/"
  }, {
    path: "/opties",
    component: _183ad766,
    pathToRegexpOptions: {"strict":true},
    name: "opties___nl"
  }, {
    path: "/poorten",
    component: _564e8b89,
    pathToRegexpOptions: {"strict":true},
    name: "poorten___nl"
  }, {
    path: "/",
    component: _39d729a0,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/configurator/:slug?",
    component: _f741795c,
    pathToRegexpOptions: {"strict":true},
    name: "configurator-slug___nl"
  }, {
    path: "/:slug",
    component: _38208858,
    pathToRegexpOptions: {"strict":true},
    name: "slug___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
